<template>
  <div>
    <h2 class="profile">Withdrawal List</h2>
    <div class="incomeList pc">
      <el-table :data="tableData" style="width: 85%" v-loading="loading">
        <el-table-column prop="createtime" align="center" label="Submission Time">
          <template #default="scope">
            <span v-if="scope.row.createtime">{{
              timeFormat(scope.row.createtime)
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="changeMoney" align="center" label="Cash Amt.">
          <template #default="scope">
            <div class="_price">
              <div>$</div>
              <div>{{ scope.row.changeMoney.toFixed(2) }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="coinBalance" label="Credit Deducted" align="center" />
        <!-- <el-table-column prop="approveTime" align="center" label="Approval time">
          <template #default="scope">
            <span v-if="scope.row.approveTime">{{
              timeFormat(scope.row.approveTime)
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column> -->
        <el-table-column label="status" align="center" prop="status">
          <template #default="scope">
            <el-tag v-if="scope.row.status == 0">To be reviewed</el-tag>
            <el-tag type="success" v-else-if="scope.row.status == 1"
              >Successful audit</el-tag
            >
            <el-tag type="info" v-else-if="scope.row.status == 2">Turn down</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-list">
        <el-pagination
          v-show="state.total > 0"
          @pagination="getList"
          :total="state.total"
          :page="queryParams.pageNum"
          :limit="queryParams.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <div class="mobile">
      <hr />
    </div>

    <!-- 移动端 -->
    <div v-if="tableData && tableData.length > 0" class="mobile list">
      <div class="card" v-for="item in tableData" :key="item.id">
        <div>
          Submission Time: <span v-if="item.createtime">{{ item.createtime }}</span
          ><span v-else>--</span>
        </div>
        <div>
          Cash Amt.: <span>{{ item.changeMoney }}</span>
        </div>
        <div>
          Credit Deducted: <span>{{ item.coinBalance }}</span>
        </div>
        <!-- <div>
          Approval time: <span v-if="item.approveTime">{{ item.approveTime }}</span
          ><span v-else>--</span>
        </div> -->
        <div>
          status: <el-tag v-if="item.status == 0"><span>To be reviewed</span></el-tag>
          <el-tag type="success" v-else-if="item.status == 1"
            ><span style="color: green">Successful audit</span></el-tag
          >
          <el-tag type="info" v-else-if="item.status == 2">Turn down</el-tag>
        </div>
      </div>
    </div>
    <div v-else class="mobile noData">No Data</div>

    <div v-if="tableData && tableData.length > 0" class="mobile paging">
      <el-pagination
        small
        v-show="state.total > 0"
        :total="state.total"
        :page="queryParams.pageNum"
        :limit="queryParams.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :pager-count="8"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import { getWithList } from "@/api/center.js";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const loading = ref(false);
const tableData = ref();
const state = reactive({
  total: "",
});
const queryParams = ref({
  pageNum: "1",
  pageSize: "10",
});
onMounted(() => {
  getList();
});

const getList = async () => {
  loading.value = true;
  queryParams.value.type = 3;
  queryParams.value.consultantId = props.consultantId;
  let res = await getWithList(queryParams.value);
  if (res.code == 200) {
    tableData.value = res.rows;
    state.total = res.total;
    loading.value = false;
  } else {
    loading.value = false;
    ElMessage.error({
      message: "Sever error!",
      type: "warning",
      grouping: true,
    });
  }
};
const handleSizeChange = (val) => {
  queryParams.value.pageNum = val;
  getList();
};
const handleCurrentChange = (val) => {
  queryParams.value.pageNum = val;
  getList();
};
//时间转换
const timeFormat = (val) => {
  return dayjs(String(val)).format("YYYY-MM-DD");
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }
  .incomeList {
    padding: 30px 20px;

    ._price {
      display: flex;
      margin-left:38px;
      width:100px;
      div{
        width:50%;
        text-align:center;
      }
    }
  }

  .profile {
    line-height: 50px;
    border-bottom: 1px solid #c2bcbc;
    padding-left: 20px;
    color: #02758a;
  }

  .page-list {
    display: flex;
    justify-content: right;
    margin-top: 50px;
    margin-right: 200px;
  }
}

//移动端
@media screen and (max-width: 768px) {
  .noData {
    text-align: center;
    font-size: 20px;
  }
  .pc {
    display: none;
  }

  .list {
    .card {
      background: #eee;
      margin-top: 20px;
      padding: 10px;
      border-radius: 6px;

      div {
        line-height: 25px;
        font-size: 18px;

        span {
          color: red;
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
  }

  .paging {
    margin-top: 20px !important;
  }
}
</style>
